import styled from 'styled-components';
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';
import { Link as LinkS } from 'react-scroll';

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 600px;
    position: relative;
    z-index: 1;

    before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: liner-gradient(180deg, rgba(0,0,0,0.2) 0%,
        rgba(0,0,0,0.6) 100%), liner-gradient(180deg, rgba(0,0,0,0.2) 0%,
        transparent 100%);
         z-index: 2;
    }

    @media screen and (max-width:760px) {
        width: auto;
    }
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* margin-top: 80px; */


    @media screen and (max-width: 480px) {
    object-fit: contain;
    flex-direction: column;
    padding-bottom: 0;
    }
`;


export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`;

export const HeroContent = styled.div`
    z-index: 3;
    margin-top: 5rem;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 760 ) {
        margin: 0;
    }
`;

export const HeroH1 = styled.div`
    color: #fff;
    font-size: 48px;
    align-items: flex-start;
    font-weight: 500;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const HeroH2 = styled.div`
    color: #fff;
    margin-top: 4px;
    margin-bottom: -20px;
    font-size: 28px;
    text-align: center;
    font-weight: 500;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 20px;
    }
`;


export const HeroP = styled.div`
    margin-top: 24px;
    margin-bottom: 6px;
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        font-size: 20px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;
export const HeroBtnWrapper = styled.div`
    margin-top: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -150px;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`;

export const Button = styled(LinkS)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#497b3f  ' : '#fff')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#fff' : '#fff')};
    font-size: ${({fontBig}) => (fontBig? '20px' : '16px')};
    outline:none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#c43817' : '#c43817')       
    };
}
`